body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.chatbot-section {
  position: fixed;
  bottom: 60px;
  right: 0;
  width: 300px;
  height: 0%;
  background-color: #f7f7f7;
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
  z-index: 1000;
  right: 10px !important;
   /* overflow: hidden; */
}

.chatbot-section.open {
  /* top: 100px !important; */
  right: 10px !important;
  bottom: 20px !important;
  width: 300px;
  max-width: 300px;
  height: 500px;
  max-height: 80%;

  display: flex;
  flex-direction: column;

  justify-content: space-between;
}

.chatbot-section .header {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(39, 39, 39, 0.2);

}

.chatbot-section .header button {
  background-color: transparent;
  border: none;
  color: white;
}

.chatbot-section .content {
  flex-grow: 1;
  /* // padding: 20px; */
  overflow-y: auto;
  max-height : 70%
}


.message {
  display: flex;
  margin: 10px;
  align-items: flex-end;
}

.user {
  justify-content: flex-start;
}

.bot {
  justify-content: flex-end;
}

.user-image{
  margin-right: 0px !important;
}

.bot-image{
  margin: 10px;
}

.message-header {

  display: flex;
  align-items: center;


}

.message-name {
  margin: 0;
  padding-inline: 5px;
}

.message-content {
  display: flex;
  /* // max-width: 70%; */
  flex-direction: column;

}

.user .message-content {
  /* // flex-direction: row; */
}

.bot .message-content {
  /* // flex-direction: row-reverse; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.bot .message-header {
  align-self: end;
}

.message-photo img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.message-text {
  background-color: #f0f0f0;
  padding: 8px;
  border-radius: 8px;
  max-width: 80%;
}

.user .message-text {
  background-color: #007bff;
  color: white;
  margin-left: 10px;
}

.user .message-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.message-input {
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  border-top: 1px solid #ccc;
  bottom: 10px;
  padding: 10px;
  height: 90px;
}

.input-field {
  flex-grow: 1;
  border: none;
  padding: 8px;
  border-radius: 10px;
  background-color: white;
  font-size: 14px;
  resize: none; /* Prevents textarea from being resizable */
  outline: none;
}

.send-button {
  background-color: #f7f7f7;
  border: none;
  color: white;
  padding: 10px 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin : 5px
}

.send-button:hover {
  background-color: #EFEFEF;
}

.send-icon {
  width : 20px
}


.logo-aereo{
  display: flex;
  align-items: center;
  /* // margin-bottom: 7px; */
}

.logo-text{
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.text-with-shadow {
  font-family: "Motiva Sans Bold", sans-serif;
  font-size: 36px;
  font-weight: 700;
  color: #FF39B3;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}

.margin-top{
 height: 50px;
}

.d-flex{
  display: flex;
}
.list-text{
  text-align: start;
  font-size: 13px;
  color: black;
  margin-left: 20px;
}

ul{
  margin: 0;
}

.reasons-title{
  font-size: 18px;
  color: #543fff;
  font-weight: bolder;
  margin-bottom: 5px
}

.reasons-content{
  font-size: 15px;
  text-align: center;
}

.reasons-main-title{
  font-size: 20px;
  text-align: center;
}
.siguenos-main-title{
  font-size: 38px;
  text-align: center;
}
.center-child{
  display: flex;
  justify-content: center;
}

.form-title{
  color: #fff;
  text-align: center;
font-size: 20px;
padding: 10px;
}

.align-end{
  align-items: end;
}

.p-5{
  padding: 10px;
}

#countryCode{
  width: 80px;
  height: 100%;
  padding: 1rem 0;
  margin-bottom: 10px;
  background-color: #F1F2F3;
  border-radius: 7px

}

#travelPlan{
  width: 90%;
  padding: 1rem 0;
  margin-bottom: 10px;
  background-color: #F1F2F3;
  border-radius: 7px;
  margin: 1rem
}

input::selection {
  color: #black;
  background-color: #fff !important;
}


.bg-form{
background-color: #543fff;
border-radius:30px;
padding: 1rem;
}

.btn-form{
background-color: #543fff;
color: #fff;
text-align: center;
font-size: 20px;
font-weight: bolder;
padding: 10px;
padding-left: 30px;
border-radius: 15px;
border: none;
padding-right: 30px;
margin: 10px;


}

.no-decition{
transform: skewX(-20deg);
font-weight: bolder;
color: #eb07aa;
text-align: center;

}

.around{
justify-content: space-around;
}

.mt-5{
margin-top: 50px
}